import React, { useState, useEffect, useRef } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import './App.css';
import Header1 from './components/Header/Header1';
import Header2 from './components/Header/Header2';
import Header3 from './components/Header/Header3';
import Header5 from './components/Header/Header5';
import Text1 from 'components/Other/Text1';
import Text2 from 'components/Other/Text2';
import Text3 from 'components/Other/Text3';
import Text4 from 'components/Other/Text4';
import Button1 from './components/Button/Button1';
import Button2 from './components/Button/Button2';
import Button3 from './components/Button/Button3';
import Button4 from './components/Button/Button4';
import Input1 from './components/Input/Input1';
import Input2 from './components/Input/Input2';
import Checkbox1 from './components/Input/Checkbox1';
import Checkbox2 from 'components/Input/Checkbox2';
import Radio1 from 'components/Input/Radio1';
import Radio2 from 'components/Input/Radio2';
import ResizableHr from './components/Other/ResizableHr';
import ResizableArea from 'components/Other/ResizableArea';
import DangerAlert from 'components/Other/DangerAlert';
import Pagination from 'components/Other/Pagination';
import Placeholder from 'components/Other/Placeholder';
import ColoredBox from 'components/Other/ColoredBox';
import ColoredSquareBox from 'components/Other/ColoredSquareBox';
import ColoredCircleOutline from 'components/Other/ColoredCircleOutline';
import Image from 'components/Other/Image';
import Portal from './components/Portal';
import Modal from './components/Modal';
import { sidebarElements } from 'config';
import type { EditorElement } from 'types';
import { handleExport, handleImport } from 'utils/helpers';

const App: React.FC = () => {
  const [editorElements, setEditorElements] = useState<EditorElement[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editElement, setEditElement] = useState<EditorElement | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const undoStackRef = useRef<EditorElement[][]>([]);
  const redoStackRef = useRef<EditorElement[][]>([]);
  const isUndoRedoAction = useRef(false);
  const pageReady = useRef(false);

  // Effect to load elements from localStorage on page load
  useEffect(() => {
    const savedElements = localStorage.getItem('editorElements');
    const savedUndoStack = localStorage.getItem('undoStack');
    const savedRedoStack = localStorage.getItem('redoStack');
    setEditorElements(JSON.parse(savedElements || '[]'));
    if (savedUndoStack) undoStackRef.current = JSON.parse(savedUndoStack);
    if (savedRedoStack) redoStackRef.current = JSON.parse(savedRedoStack);
  }, []);

  // Effect to save editor elements to local storage whenever they change
  useEffect(() => {
    if (!pageReady.current) {
      pageReady.current = true;
      return;
    }

    // dont do anything if nothing changes
    if (JSON.stringify(editorElements) === localStorage.getItem('editorElements')) return;

    // Save the current state to localStorage
    localStorage.setItem('editorElements', JSON.stringify(editorElements));

    // Skip saving to undo/redo stacks if the change is due to an undo or redo action
    if (isUndoRedoAction.current) {
      isUndoRedoAction.current = false;
      return;
    }

    // Update the undo stack with the current state
    undoStackRef.current.push(editorElements);
    redoStackRef.current = []; // Clear redo stack on new action

    // Limit the undo stack size to 100 items
    if (undoStackRef.current.length > 100) {
      undoStackRef.current.shift();
    }

    // Manually update the undo and redo stacks in local storage
    updateLocalStorage();
  }, [editorElements]);

  // Function to manually update localStorage with the undo/redo stacks
  const updateLocalStorage = () => {
    localStorage.setItem('undoStack', JSON.stringify(undoStackRef.current));
    localStorage.setItem('redoStack', JSON.stringify(redoStackRef.current));
  };

  // Undo function
  const handleUndo = () => {
    if (undoStackRef.current.length > 1) {
      isUndoRedoAction.current = true; // Mark this change as an undo/redo action

      const lastState = undoStackRef.current.pop();
      if (lastState) redoStackRef.current.push(lastState);

      // Set the editor elements to the previous state
      const previousState = undoStackRef.current[undoStackRef.current.length - 1];
      setEditorElements(previousState);

      // Manually update the undo and redo stacks in local storage
      updateLocalStorage();
    }
  };

  // Redo function
  const handleRedo = () => {
    if (redoStackRef.current.length > 0) {
      isUndoRedoAction.current = true; // Mark this change as an undo/redo action

      const redoState = redoStackRef.current.pop();
      if (redoState) {
        undoStackRef.current.push(redoState);
        setEditorElements(redoState);

        // Manually update the undo and redo stacks in local storage
        updateLocalStorage();
      }
    }
  };

  const handleDragStart = (e: React.DragEvent, element: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    // Calculate the cursor offset relative to the element's top-left corner
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setDragOffset({ x: offsetX, y: offsetY });
  };

  const handleDrop = (e: React.DragEvent, element: any) => {
    const editorRect = document.querySelector('.editor')?.getBoundingClientRect();

    if (!editorRect) return;

    if (e.clientX > editorRect.left && e.clientX < editorRect.right &&
        e.clientY > editorRect.top && e.clientY < editorRect.bottom) {
      const newElement = {
        ...element,
        id: `${editorElements.length + 1}-${element.id}-${Math.random()}-${Date.now()}`,
        position: {
          // Subtract the offset captured during drag start to place correctly
          x: e.clientX - editorRect.left - dragOffset.x,
          y: e.clientY - editorRect.top - dragOffset.y
        }
      };

      setEditorElements([...editorElements, newElement]);
    }
  };

  const handleDragStop = (e: DraggableEvent, data: DraggableData, id: string) => {
    setEditorElements(prevState =>
      prevState.map(el => (el.id === id ? { ...el, position: { x: data.x, y: data.y } } : el))
    );
  };

  const handleResizeStop = (id: string, width: number, height: number) => {
    setEditorElements(prevState =>
      prevState.map(el => (el.id === id ? { ...el, width, height } : el))
    );
  };

  const handleEditClick = (element: EditorElement) => {
    setEditElement(element);
    setShowModal(true);
  };

  const handleEditSave = (updatedOptions: string) => {
    if (editElement) {
      setEditorElements(prevState =>
        prevState.map(el => (el.id === editElement.id ? { ...el, content: updatedOptions } : el))
      );
      setEditElement(null);
      setShowModal(false);
    }
  };

  const handleDelete = (id: string) => {
    setEditorElements(prevState => prevState.filter(el => el.id !== id));
  };

  const handleBringFront = (id: string) => {
    setEditorElements(prevState => {
      const element = prevState.find(el => el.id === id);
      if (!element) return prevState;
      return [element, ...prevState.filter(el => el.id !== id)];
    });
  }

  const handlePushBack = (id: string) => {
    setEditorElements(prevState => {
      const element = prevState.find(el => el.id === id);
      if (!element) return prevState;
      return [...prevState.filter(el => el.id !== id), element];
    });
  }

  const handleDuplicate = (id: string) => {
    const element = editorElements.find(el => el.id === id);
    if (!element) return;

    const newElement = {
      ...element,
      id: `${editorElements.length + 1}-${element.id}-${Math.random()}-${Date.now()}`,
      position: {
        x: element.position.x + 20,
        y: element.position.y + 20
      }
    };

    setEditorElements([...editorElements, newElement]);
  }

  const renderElement = (element: EditorElement) => {
    switch (element.type) {
      case 'header1':
        return <Header1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'header2':
        return <Header2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'header3':
        return <Header3
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'header5':
        return <Header5
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'text1':
        return <Text1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'text2':
        return <Text2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'text3':
        return <Text3
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'text4':
        return <Text4
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'button1':
        return <Button1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'button2':
        return <Button2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'button3':
        return <Button3
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'button4':
        return <Button4
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'input1':
        return <Input1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'input2':
        return <Input2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'checkbox1':
        return <Checkbox1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'checkbox2':
        return <Checkbox2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'radio1':
        return <Radio1
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'radio2':
        return <Radio2
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'horizontal-row':
        return (
          <ResizableHr
            id={element.id}
            width={element.width || 160}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'resizable-area':
        return (
          <ResizableArea
            id={element.id}
            width={element.width || 160}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'danger-alert':
        return <DangerAlert
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack}
          duplicate={handleDuplicate}
        />;
      case 'pagination':
        return <Pagination
          id={element.id}
          initialContent={element.content}
          onEdit={() => handleEditClick(element)}
          onDelete={handleDelete}
          bringFront={handleBringFront}
          pushBack={handlePushBack} 
          duplicate={handleDuplicate}
        />;
      case 'placeholder':
        return (
          <Placeholder
            id={element.id}
            initialContent={element.content}
            width={element.width || 160}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'colored-box':
        return (
          <ColoredBox
            id={element.id}
            initialContent={element.content}
            width={element.width || 160}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'colored-square-box':
        return (
          <ColoredSquareBox
            id={element.id}
            initialContent={element.content}
            width={element.width || 160}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'colored-circle-outline':
        return (
          <ColoredCircleOutline
            id={element.id}
            initialContent={element.content}
            width={element.width || 50}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      case 'image':
        return (
          <Image
            id={element.id}
            initialContent={element.content}
            width={element.width || 160}
            height={element.height || 50}
            onResizeStop={handleResizeStop}
            onEdit={() => handleEditClick(element)}
            onDelete={handleDelete}
            bringFront={handleBringFront}
            pushBack={handlePushBack}
            duplicate={handleDuplicate}
          />
        );
      default:
        return <div>{element.content}</div>;
    }
  };

  return (
    <div className="App">
      <div className="main-container">
        {isSidebarOpen && (
          <div className="elements-sidebar">
            <h2>Elements</h2>
            <ul className='element-settings' style={{marginBottom: '5px'}}>
              <li><button className='element-settings-button' onClick={() => setEditorElements([])}>Clear</button></li>
              <li><button className='element-settings-button'  onClick={() => handleExport(editorElements, window.innerWidth, window.innerHeight)}>Export</button></li>
              <li><button className='element-settings-button' onClick={() => handleImport(setEditorElements, editorElements, window.innerWidth, window.innerHeight)}>Import</button></li>
            </ul>
            <ul className='element-settings'>
              <li><button className='element-settings-button' onClick={handleUndo}>Undo</button></li>
              <li><button className='element-settings-button' onClick={handleRedo}>Redo</button></li>
            </ul>
            <hr></hr>
            {sidebarElements.map((element) => (
              
              <><div
                key={element.id}
                draggable
                onDragStart={(e) => handleDragStart(e, element)}
                onDragEnd={(e) => handleDrop(e, element)}
                className={`draggable-element sidebar-element`}
              >
                {renderElement({ ...element, position: { x: 0, y: 0 } })}
              </div><br></br></>
            ))}
          </div>
        )}
        <div className={`editor ${isSidebarOpen ? 'with-sidebar' : 'without-sidebar'}`}>
          <div className="toggle-sidebar" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <span>{isSidebarOpen ? '\u25C0' : '\u25B6'}</span>
          </div>
          {editorElements.map((element) => (
            <Draggable
              key={element.id}
              position={element.position}
              onStop={(e, data) => handleDragStop(e, data, element.id)}
              handle='.move-icon'
            >
              <div className="draggable-element" style={{ width: element.width || 'auto' }}>
                {renderElement(element)}
              </div>
            </Draggable>
          ))}
          <Portal />
        </div>
      </div>
      {showModal && editElement && (
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          onSave={handleEditSave}
          initialValue={ editElement.content }
        />
      )}
    </div>
  );
};

export default App;
