import React from 'react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import BaseElement from 'components/BaseElement';
import './ResizableArea.css';

interface ResizableAreaProps {
  id: string;
  width: number;
  height: number;
  onResizeStop: (id: string, width: number, height: number) => void;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const ResizableArea: React.FC<ResizableAreaProps> = ({ id, width, height, onResizeStop, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  const handleResizeStop = (event: React.SyntheticEvent, data: { size: { width: number, height: number } }) => {
    onResizeStop(id, data.size.width, data.size.height);
  };

  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
        <div className="base-element resizable-area-container">
        <ResizableBox
            width={width}
            height={height}
            axis="both"
            resizeHandles={['se']}
            onResizeStop={handleResizeStop}
            minConstraints={[50, 50]}
            maxConstraints={[5000, 5000]}
            className="resizable-area"
        >
            <div className="resizable-area-content">
            
            </div>
        </ResizableBox>
        </div>
    </BaseElement>
  );
};

export default ResizableArea;
