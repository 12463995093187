import React from 'react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './ResizableHr.css';
import BaseElement from 'components/BaseElement';

interface ResizableHrProps {
  id: string;
  width: number;
  onResizeStop: (id: string, width: number, height: number) => void;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const ResizableHr: React.FC<ResizableHrProps> = ({ id, width, onResizeStop, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  const handleResizeStop = (event: React.SyntheticEvent, data: { size: { width: number, height: number } }) => {
    onResizeStop(id, data.size.width, data.size.height);
  };

  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
        <div className="base-element resizable-hr-container">
        <ResizableBox
            width={width}
            height={20} // Small height to make the line visible for resizing
            axis="x" // Only allow horizontal resizing
            resizeHandles={['e']} // Allow resizing from the right edge
            onResizeStop={handleResizeStop}
            minConstraints={[50, 20]} // Minimum width and height
            maxConstraints={[5000, 20]} // Maximum width and height
            className="resizable-box"
        >
            <div className="resizable-hr-content">
            <hr style={{ width: '100%' }} />
            </div>
        </ResizableBox>
        </div>
    </BaseElement>
  );
};

export default ResizableHr;
