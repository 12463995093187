import React from 'react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import BaseElement from 'components/BaseElement';
import './ColoredCircleOutline.css';

interface ColoredCircleOutlineProps {
  id: string;
  initialContent: string;
  width: number;
  height: number;
  onResizeStop: (id: string, width: number, height: number) => void;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const ColoredCircleOutline: React.FC<ColoredCircleOutlineProps> = ({ id, initialContent, width, height, onResizeStop, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  const handleResizeStop = (event: React.SyntheticEvent, data: { size: { width: number, height: number } }) => {
    onResizeStop(id, data.size.width, data.size.height);
  };

  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
        <div className="base-element resizable-colored-circle-outline-container">
            <ResizableBox
                width={width}
                height={height}
                axis="both"
                resizeHandles={['se']}
                onResizeStop={handleResizeStop}
                minConstraints={[20, 20]}
                maxConstraints={[5000, 5000]}
                className="resizable-area"
                lockAspectRatio={true}
                style={{ borderColor: initialContent, borderWidth: '2px', borderStyle: 'solid'}}
            >
                <div className="resizable-colored-circle-outline-content">
                    <div>
                        
                    </div>
                </div>
            </ResizableBox>
        </div>
    </BaseElement>
  );
};

export default ColoredCircleOutline;
