export const sidebarElements = [
  { id: '1', type: 'header1', content: 'Header 1' },
  { id: '2', type: 'header2', content: 'Header 2' },
  { id: '3', type: 'header3', content: 'Header 3' },
  { id: '4', type: 'header5', content: 'Header 5' },
  { id: '5', type: 'text1', content: 'Text 1' },
  { id: '6', type: 'text2', content: 'Text 2' },
  { id: '21', type: 'text3', content: 'Text 3' },
  { id: '22', type: 'text4', content: 'Text 4' },
  { id: '7', type: 'button1', content: 'Button 1' },
  { id: '8', type: 'button2', content: 'Button 2' },
  { id: '9', type: 'button3', content: 'Button 3' },
  { id: '10', type: 'button4', content: 'Button 4' },
  { id: '11', type: 'input1', content: 'Input 1' },
  { id: '12', type: 'input2', content: 'Input 2' },
  { id: '13', type: 'checkbox1', content: 'Checkbox 1' },
  { id: '14', type: 'checkbox2', content: 'Checkbox 2' },
  { id: '23', type: 'radio1', content: 'Radio 1' },
  { id: '24', type: 'radio2', content: 'Radio 2' },
  { id: '15', type: 'horizontal-row', content: 'Horizontal Row' },
  { id: '16', type: 'resizable-area', content: 'Resizable Area' },
  { id: '17', type: 'danger-alert', content: 'Danger Alert' },
  { id: '18', type: 'pagination', content: 'Pagination' },
  { id: '19', type: 'placeholder', content: 'Placeholder' },
  { id: '20', type: 'colored-box', content: '#d4310F' },
  { id: '25', type: 'colored-square-box', content: '#d4310F' },
  { id: '26', type: 'colored-circle-outline', content: '#d4310F' },
  { id: '27', type: 'image', content: 'Insert Image URL Here' },
];
  