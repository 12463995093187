import type { EditorElement } from '../types';

export const handleExport = (elements: EditorElement[], availWidth: number, availHeight: number) => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify({elements, availWidth, availHeight})], { type: 'application/json' });
    element.href = URL.createObjectURL(file);
    element.download = 'PortalDesignerExport.json';
    document.body.appendChild(element);
    element.click();
  };
  
  export const handleImport = (setEditorElements: any, elements: EditorElement[], availWidth: number, availHeight: number) => {
    const element = document.createElement('input');
    element.type = 'file';
    element.accept = '.json';
    element.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const result = e.target?.result;
          if (result) {
            const data = JSON.parse(result as string);
            if (data.availWidth !== availWidth || data.availHeight !== availHeight) {
              alert('The file was exported for a different screen size. The positions and sizes have been adjusted although there may still be compatibility issues.');
            }
            const widthRatio = availWidth / data.availWidth;
            const heightRatio = availHeight / data.availHeight;
            data.elements.forEach((el: any) => {
              el.position.x = ((el.position.x-200) * widthRatio) + 200;
              el.position.y *= heightRatio;
              if (el.width) el.width =  el.width * widthRatio - (200-(200*widthRatio));
              if (el.height) el.height *= heightRatio;
            });
            setEditorElements([...elements, ...data.elements]);
          }
        };
        reader.readAsText(file);
      }
    };
    element.click();
  };
  