import React from 'react';
import BaseElement from '../BaseElement';
import './Header1.css';

interface Header1Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Header1: React.FC<Header1Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <h1 className='header1'>{initialContent}</h1>
    </BaseElement>
  );
};

export default Header1;
