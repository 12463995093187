import React from 'react';
import BaseElement from '../BaseElement';

interface DangerAlertProps {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const DangerAlert: React.FC<DangerAlertProps> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <div className="alert alert-danger" role="alert">
        {initialContent}
      </div>
    </BaseElement>
  );
};

export default DangerAlert;
