import React, { useState, useEffect } from 'react';
import './Modal.css';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (text: string) => void;
  initialValue: string;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, onSave, initialValue }) => {
  const [text, setText] = useState(initialValue);

  useEffect(() => {
    if (show) {
      setText(initialValue);
    }
  }, [show, initialValue]);

  if (!show) {
    return null;
  }

  const handleSave = () => {
    onSave(text);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="edit-modal">
        <h2>Edit Content</h2>
        <textarea
            className="modal-textarea"
            value={text}
            onChange={(e) => setText(e.target.value)}
        />
        <div className="modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
