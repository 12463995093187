import React from 'react';
import BaseElement from '../BaseElement';

interface Text3Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Text3: React.FC<Text3Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {initialContent}
        </div>
      </div>
    </BaseElement>
  );
};

export default Text3;
