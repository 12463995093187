import React from 'react';
import BaseElement from '../BaseElement';

interface Header5Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Header5: React.FC<Header5Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <h5 className='Header5'>{initialContent}</h5>
    </BaseElement>
  );
};

export default Header5;
