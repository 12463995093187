import React from 'react';
import './Portal.css';

const Portal: React.FC = () => {
  return (
    <div className="app d-flex">
        <nav id="sidebarMenu" className="d-block sidebar collapse">
            <div className="sidebar-sticky pt-3">
                <div className="row">
                    <div className="col col-auto my-auto">
                        <img src="https://portal.dev-affinitylottery.org.uk/img/WV-Logo-Stacked-100mm-CMYK-Zoned.svg" alt="Affinity logo" className="logo logo-affinity" />
                    </div>
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link" href="#Dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                            Dashboard
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#PlayerLookup">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                            Player Lookup
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#Downloads">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            Downloads
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#Uploads">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            Uploads
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#Users">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            Users
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#DrawApproval">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                            Draw Approval
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#SourceCodes">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                            Source Codes
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#ClientBilling">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="feather" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" d="M160 336V48l32 16 32-16 31.94 16 32.37-16L320 64l31.79-16 31.93 16L416 48l32.01 16L480 48v224"></path><path d="M480 272v112a80 80 0 01-80 80h0a80 80 0 01-80-80v-48H48a15.86 15.86 0 00-16 16c0 64 6.74 112 80 112h288" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M224 144h192M288 224h128"></path></svg>
                            Client Billing
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#SystemBilling">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="feather" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" d="M160 336V48l32 16 32-16 31.94 16 32.37-16L320 64l31.79-16 31.93 16L416 48l32.01 16L480 48v224"></path><path d="M480 272v112a80 80 0 01-80 80h0a80 80 0 01-80-80v-48H48a15.86 15.86 0 00-16 16c0 64 6.74 112 80 112h288" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M224 144h192M288 224h128"></path></svg>
                            System Billing
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#ClientSettings">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="feather" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                            Client Settings
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#ScheduledJobs">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock feather" viewBox="0 0 16 16">
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
                            </svg>
                            Scheduled Jobs
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <main className="dashboard-main" role="main">
            <div className="container-fluid d-flex h-100 flex-column py-2">
                <div className="row">
                    <div className="col">
                        <header className="header">
                            <div className="container-fluid px-0">
                                <div className="row no-gutters">
                                    <div className="col my-auto">
                                        <h1 className="title">
                                            <span className="site-title"><a id="clientPortalLink" href="#LoggedIn">Client portal</a></span>
                                        </h1>
                                    </div>                          
                                    <div className="col-auto my-auto">
                                        <div className="form-inline lookup-nav-client">
                                            <span>Inactive?  &nbsp; <input id="input-client-inactive" name="inActive" type="checkbox" value="true" /><input name="inActive" type="hidden" value="false" /> &nbsp;</span>                                    
                                            <label htmlFor="input-title" className="col-form-label col-form-label-sm">Client</label>
                                            <select className="form-control form-control-sm" id="input-client" style={{ color: 'black' }}>
                                                <option value="WoodsValldata" style={{ color: 'black' }}>
                                                    Woods Valldata
                                                </option>
                                                <option value="demo" style={{ color: 'black' }}>
                                                    Demo
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-auto my-auto mr-3">
                                        <form className="form-inline" onSubmit={(event) => { event.preventDefault(); return false; }}>
                                            <button type="submit" id="logout-submit" className="btn btn-outline-primary btn-sm lookup-nav-button">Log Out <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg></button>
                                        </form>
                                    </div>
                                    <div className="col col-auto my-auto">
                                        <img src="https://portal.dev-affinitylottery.org.uk/img/WV-Logo-Stacked-100mm-CMYK-Zoned.svg" style={{ maxHeight: '78px' }} alt="Client Logo" className="logo" />
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
        </main>
    </div>
  );
};

export default Portal;
