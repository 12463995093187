import React from 'react';
import BaseElement from '../BaseElement';

interface Button3Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Button3: React.FC<Button3Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <div className="btn-toolbar">
        <button type="button" className="btn btn-outline-primary">
            {initialContent}
        </button>
    </div>
    </BaseElement>
  );
};

export default Button3;
