import React from 'react';
import { FaEdit, FaTrash, FaArrowsAlt, FaLevelUpAlt, FaLevelDownAlt, FaCopy } from 'react-icons/fa';
import './BaseElement.css';

interface BaseElementProps {
  id: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
  children: React.ReactNode;
}

const BaseElement: React.FC<BaseElementProps> = ({ id, onEdit, onDelete, bringFront, pushBack, duplicate, children }) => {
  return (
    <div className="base-element">
      <div className="element-actions">
        <FaArrowsAlt className="move-icon" title="Move Element" />
        <FaEdit className="edit-icon" title="Edit Element" onClick={onEdit} />
        <FaTrash className="delete-icon" title="Delete Element" onClick={() => onDelete(id)} />
        <FaLevelUpAlt className="up-icon" title="Send to Back" onClick={() => bringFront(id)} />
        <FaLevelDownAlt className="down-icon" title="Bring to Front" onClick={() => pushBack(id)} />
        <FaCopy className="copy-icon" title="Duplicate" onClick={() => duplicate(id)} />
      </div>
      {children}
    </div>
  );
};

export default BaseElement;
