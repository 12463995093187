import React from 'react';
import BaseElement from '../BaseElement';

interface Radio1Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Radio1: React.FC<Radio1Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
        <div className="form-group" style={{ textAlign: 'left' }}>
            <label className="col-form-label col-form-label-sm" htmlFor={`radio1${id}`} style={{display: 'block'}}>{initialContent}</label>
            <span style={{display: 'inline-block'}}>
              <input className="form-control form-control-sm" id={`radio1${id}`} type="radio" />
            </span>
        </div>
    </BaseElement>
  );
};

export default Radio1;
