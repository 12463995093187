import React from 'react';
import BaseElement from '../BaseElement';
import './Pagination.css';

interface PaginationProps {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Pagination: React.FC<PaginationProps> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <div className="pagination-container">
        <ul className="pagination">
            <li className="disabled"><button className="disabled">Previous</button></li>
            <li className="active"><button>1</button></li>
            <li className="disabled"><button>Next</button></li>
        </ul>
        <label
          htmlFor="goToPage"
          style={{
            width: '74px',
            fontSize: '12.8px',
            display: 'inline-block',
            margin: '25px 0px',
            verticalAlign: 'top'
          }}>Go to Page</label>
        <input
          className="form-control"
          id="goToPage"
          name="goToPage"
          style={{
            width: '45px',
            fontSize: '12.8px',
            display: 'inline',
            margin: '20px 0px',
            verticalAlign: 'top'
          }}
          type="text"
          value=""
        />
        <input
          type="button"
          className="form-control"
          style={{
            display: 'inline-flex',
            width: '41px',
            height: '33px',
            verticalAlign: 'top',
            fontSize: '14px',
            marginTop: '20px'
          }}
          value="Go"
        />
        <label
          htmlFor="itemsPerPageLabel"
          style={{
            width: '92px',
            fontSize: '12.8px',
            display: 'inline-block',
            margin: '18px 0px',
            verticalAlign: 'top'
          }}>Number Of Records</label>
        <select
          className="form-control"
          id="itemsPerPage"
          name="itemsPerPage"
          style={{
            width: 'unset',
            fontSize: '12.8px',
            display: 'inline',
            margin: '20px 0px',
            verticalAlign: 'top',
            fontFamily: 'Open Sans, sans-serif',
          }}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </BaseElement>
  );
};

export default Pagination;
