import React from 'react';
import BaseElement from '../BaseElement';
import './Header2.css';

interface Header2Props {
  id: string;
  initialContent: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
  bringFront: (id: string) => void;
  pushBack: (id: string) => void;
  duplicate: (id: string) => void;
}

const Header2: React.FC<Header2Props> = ({ id, initialContent, onEdit, onDelete, bringFront, pushBack, duplicate }) => {
  return (
    <BaseElement id={id} onEdit={onEdit} onDelete={onDelete} bringFront={bringFront} pushBack={pushBack} duplicate={duplicate}>
      <h2 className='Header2'>{initialContent}</h2>
    </BaseElement>
  );
};

export default Header2;
